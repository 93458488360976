module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"linkImagesToOriginal":false,"backgroundColor":"none","showCaptions":true,"quality":100,"markdownCaptions":false,"sizeByPixelDensity":false,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Basaas.com","short_name":"Basaas","start_url":"/","background_color":"#3366FF","theme_color":"#3366FF","display":"minimal-ui","icon":"./src/assets/basaas-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e71680af8a83d68d1ca0571f2154243"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
