// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-basaas-app-js": () => import("./../../../src/pages/basaas-app.js" /* webpackChunkName: "component---src-pages-basaas-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-browser-extension-js": () => import("./../../../src/pages/browser-extension.js" /* webpackChunkName: "component---src-pages-browser-extension-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-extension-uninstall-js": () => import("./../../../src/pages/extension-uninstall.js" /* webpackChunkName: "component---src-pages-extension-uninstall-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-time-to-switch-to-the-browser-basaas-app-eol-index-js": () => import("./../../../src/pages/info/time-to-switch-to-the-browser-basaas-app-eol/index.js" /* webpackChunkName: "component---src-pages-info-time-to-switch-to-the-browser-basaas-app-eol-index-js" */),
  "component---src-pages-info-your-browser-is-your-real-digital-workplace-index-js": () => import("./../../../src/pages/info/your-browser-is-your-real-digital-workplace/index.js" /* webpackChunkName: "component---src-pages-info-your-browser-is-your-real-digital-workplace-index-js" */),
  "component---src-pages-lp-digital-employee-experience-dex-index-js": () => import("./../../../src/pages/lp/digital-employee-experience-dex/index.js" /* webpackChunkName: "component---src-pages-lp-digital-employee-experience-dex-index-js" */),
  "component---src-pages-lp-digital-transformation-index-js": () => import("./../../../src/pages/lp/digital-transformation/index.js" /* webpackChunkName: "component---src-pages-lp-digital-transformation-index-js" */),
  "component---src-pages-lp-digital-workplace-index-js": () => import("./../../../src/pages/lp/digital-workplace/index.js" /* webpackChunkName: "component---src-pages-lp-digital-workplace-index-js" */),
  "component---src-pages-lp-employee-onboarding-index-js": () => import("./../../../src/pages/lp/employee-onboarding/index.js" /* webpackChunkName: "component---src-pages-lp-employee-onboarding-index-js" */),
  "component---src-pages-lp-employee-productivity-index-js": () => import("./../../../src/pages/lp/employee-productivity/index.js" /* webpackChunkName: "component---src-pages-lp-employee-productivity-index-js" */),
  "component---src-pages-lp-find-everything-in-microsoft-365-quick-and-easy-index-js": () => import("./../../../src/pages/lp/find-everything-in-microsoft365-quick-and-easy/index.js" /* webpackChunkName: "component---src-pages-lp-find-everything-in-microsoft-365-quick-and-easy-index-js" */),
  "component---src-pages-lp-intranet-from-existing-apps-index-js": () => import("./../../../src/pages/lp/intranet-from-existing-apps/index.js" /* webpackChunkName: "component---src-pages-lp-intranet-from-existing-apps-index-js" */),
  "component---src-pages-lp-new-era-for-digital-work-js": () => import("./../../../src/pages/lp/new-era-for-digital-work.js" /* webpackChunkName: "component---src-pages-lp-new-era-for-digital-work-js" */),
  "component---src-pages-lp-new-work-environment-index-js": () => import("./../../../src/pages/lp/new-work-environment/index.js" /* webpackChunkName: "component---src-pages-lp-new-work-environment-index-js" */),
  "component---src-pages-lp-optimizing-remote-work-index-js": () => import("./../../../src/pages/lp/optimizing-remote-work/index.js" /* webpackChunkName: "component---src-pages-lp-optimizing-remote-work-index-js" */),
  "component---src-pages-lp-saas-app-cloud-link-management-index-js": () => import("./../../../src/pages/lp/saas-app-cloud-link-management/index.js" /* webpackChunkName: "component---src-pages-lp-saas-app-cloud-link-management-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-tasks-index-js": () => import("./../../../src/pages/tasks/index.js" /* webpackChunkName: "component---src-pages-tasks-index-js" */),
  "component---src-pages-workplace-index-js": () => import("./../../../src/pages/workplace/index.js" /* webpackChunkName: "component---src-pages-workplace-index-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simple-page.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

